import React, { useState } from "react"

import Modal from "../modal/modal"

import { DemoBarStyles } from "./demo-sitebar-styles"

import DemoSiteDetails from "../demo-site-details/demo-site-details"

export default () => {
  const [modalVisible, setModalVisible] = useState(false)

  const showModal = e => {
    e.preventDefault()
    setModalVisible(true)
  }
  const hideModal = () => setModalVisible(false)

  return (
    <DemoBarStyles>
      <span>This is a demo site</span>
      <a href="" onClick={showModal}>
        About this demo site
      </a>
      {modalVisible && (
        <Modal title="About this site" hide={hideModal}>
          <DemoSiteDetails />
        </Modal>
      )}
    </DemoBarStyles>
  )
}
