import styled from "styled-components"

export const DemoBarStyles = styled.div`
  background: linear-gradient(to bottom, #f48c42, #ba5209, #f48c42) #f48c42;
  padding: 10px 20px;
  font-weight: bold;
  color: #fff;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
