import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';

export default ({ children }) => {

    const portalRoot = document.getElementById("portal");

    /* Needed for gatsby - https://www.gatsbyjs.org/packages/gatsby-plugin-portal/ */
    // const el = typeof document !== `undefined` ? document.createElement('div') : null
    const el = document.createElement('div');

    useEffect(() => {
        
        console.log('adding element.')
        portalRoot.appendChild(el);
        
        return () => {
            // Clean up
            console.log('removing element.')
            portalRoot.removeChild(el);
        };

    }, [])

    return ReactDOM.createPortal(children, el);        
        

}