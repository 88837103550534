import styled from "styled-components"

export const ModalBackgroundStyles = styled.div`
  position: fixed;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(51, 51, 51, 0.8);
`

export const ModalContainerStyles = styled.div`
  background-color: #fff;
  border-radius: 8px;

  max-width: 900px;

  width: 80vw;
  height: 80vh;

  display: flex;
  flex-direction: column;

  box-shadow: 5px 5px 5px #333;
`

export const ModalHeaderStyles = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;

  & > h3 {
    flex-grow: 1;
    text-align: center;
    color: #777;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }

  & > button {
    padding: 10px;
    margin: 5px;
    cursor: pointer;
  }
`

export const ModalContentStyles = styled.div`
  padding: 20px;
  height: 100%;
  overflow: scroll;
`
