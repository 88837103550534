import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { StyledHeader, StyledNavigation } from "./header-styles"

const Header = ({ siteTitle }) => (
  <header>
    <StyledHeader>{siteTitle}</StyledHeader>
    <StyledNavigation>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/projects/1">Projects</Link>
        </li>
      </ul>
    </StyledNavigation>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
