import styled from "styled-components"

export const StyledHeader = styled.h1`
  background-color: #6b5b7a;
  font-size: 7.2rem;
  color: #fff;
  font-weight: normal;
  font-family: "Bobby Rough";
  white-space: nowrap;
  padding: 0 20px;

  @media (max-width: 700px) {
    text-align: center;
  }

  @media (max-width: 550px) {
    font-size: 5rem;
  }
`

export const StyledNavigation = styled.nav`
  margin-bottom: 1.6rem;
  background-color: #483f51;
  margin: 0;

  ul {
    list-style-type: none;
    padding-left: 1rem;

    li {
      display: inline-block;
      margin-right: 1rem;

      a {
        text-decoration: none;
        color: #fff;
        padding: 10px;
        display: block;
        font-size: 1.6rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media (max-width: 550px) {
      text-align: center;
    }
  }
`
