import styled, { createGlobalStyle } from "styled-components"

import BobbyRoughWoff from "../../fonts/BobbyRough-Regular.woff"
import BobbyRoughWoff2 from "../../fonts/BobbyRough-Regular.woff2"

export const GlobalStyles = createGlobalStyle`

  #portal {
    z-index: 1000;
  }

  @font-face {
    font-family: 'Bobby Rough';
    src:  url(${BobbyRoughWoff2}) format('woff2'),
          url(${BobbyRoughWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  html {
    font-size: 62.5%;
  }
  
  body {
    font-size: 1.6rem;
    line-height: 1.5;
    background-color: #ddd;

    font-family: sans-serif;
  }

  p {
    margin-bottom: 1.6rem;
  }

  main {
    padding: 0;
  }
`

export const LayoutStyles = styled.div`
  max-width: 960px;
  margin: 0 auto;
  background-color: #fff;
`

export const MainStyles = styled.main`
  padding: 20px;
`
