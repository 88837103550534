import React from "react"

import { StyledFooter } from "./footer-styles"

const Footer = () => (
  <StyledFooter>
    <p>A demo gatsby website by Rob Bowen</p>
  </StyledFooter>
)

export default Footer
