import React from "react"

import Portal from "../portal/portal"

import {
  ModalBackgroundStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
} from "./modal-styles"

export default ({ children, hide, title }) => {
  return (
    <Portal>
      <ModalBackgroundStyles onClick={hide}>
        <ModalContainerStyles onClick={e => e.stopPropagation()}>
          <ModalHeaderStyles>
            <h3>{title}</h3>
            <button onClick={hide}>X</button>
          </ModalHeaderStyles>
          <ModalContentStyles>{children}</ModalContentStyles>
        </ModalContainerStyles>
      </ModalBackgroundStyles>
    </Portal>
  )
}
