import React from 'react';

export default () => {
    return <div>
        <dl>
            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>

            <dt>What's the purpose of this site?</dt>
            <dd>Answer</dd>
        </dl>
    </div>
}